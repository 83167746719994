'use strict';

const assets = {
    noImageAvailable: 'https://static.chewba.info/images/5107d70f-d00e-4fc2-b3b9-1030e883fcd7.jpg'
};

import { getConfig } from './Env';
import AuthStore from '../stores/AuthStore';

let _config = {
    prefixes: [
        'https://static.chewba.info/',
    ],
};

/**
 * Given a raw image URL to the S3 bucket, convert it to a shortened URL
 */
export function getResizedUrl(src, width, height, debug = false) {
    src = src || '';
    let filename;

    // Do we need to append the authn token to the URL?
    if (src.indexOf('/file-proxy/') !== -1) {
        const authn = AuthStore.getToken();

        const query = [];

        if (authn) query.push('token=' + authn);
        if (width) query.push('w=' + width);
        if (height) query.push('h=' + height);

        const separator = src.includes('?') ? '&' : '?';

        return query.length > 0 ? (src + separator + query.join('&')) : src;
    }

    if (src.indexOf('.svg') !== -1) {
        return src;
    }

    // Otherwise, the image is public and we can get it from the CDN, which is (alot) faster.
    if (!_config.prefixes.includes(getConfig('static_url_prefix') + '/')) {
        _config.prefixes.push(getConfig('static_url_prefix') + '/');
    }

    _config.prefixes.forEach(prefix => {
        if (src && prefix && typeof src == 'string' && src.indexOf(prefix) !== -1) {
            filename = src.substring(prefix.length);
        }
    });

    // If we weren't able to strip a prefix, then just return the entire src as-is
    if (!filename) {
        return src;
    }

    if (width && width > 500) {
        width = undefined;
    }

    if (height && height > 500) {
        height = undefined;
    }

    if (!width && !height) {
        return src;
    }

    let params = ['s_s', 'o_1'];

    if (width) params.push('w_' + width);
    if (height) params.push('h_' + height);

    params.push(filename);

    return 'https://' + getConfig('resize_host') + '/' + params.join('/');
}

export function getBackgroundImageUrl(image, width, height) {
    return 'url(' + getResizedUrl(image || assets.noImageAvailable, width, height) + ')';
}


export function getSizedFilePreview(file) {
    let width = 200;
    let height = 100;

    if (!file.image_width || !file.image_height) {
        return { width: 200, height: 100 };
    }

    if (file.image_height > file.image_width) {
        const factor = file.image_width / file.image_height;
        width = Math.ceil(height * factor);
    } else if (file.image_width > file.image_height) {
        const factor = file.image_height / file.image_width;
        height = Math.ceil(width * factor);
    }

    return { width, height };
}
