import ReactDOM from 'react-dom';
import { useState, useEffect, useRef } from 'react';
import { PushNotifications } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import Prompt from './Prompt.react';
import Icon from './Icon.react';
import AuthStore from '@stores/AuthStore';
import UserStore from '@stores/UserStore';
import Analytics from '@utils/Analytics';
import store from 'store';
import './PushNotificationPrompt.scss';

const LOCALSTORAGE_KEY = 'push-notifications';
export const LOCALSTORAGE_KEY_DEVICE_TOKEN = 'push-notifications-device-key';

export const storeTokenInBackend = async (token, platform) => {
    try {
        const url = UserStore.getLinks().devices;
        const request = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json; schema=device/1' },
            body: JSON.stringify({ token, platform }),
        };
        const response = await AuthStore.fetch(url, request);
        if (!response.ok) {
            throw new Error('Failed to store token in backend.');
        }
        console.log('Token successfully stored in backend!');
    } catch (err) {
        console.error('Error storing token in backend:', err.message);
    }
};

const PushNotificationPrompt = ({ onClose = () => {}, force = false, delay = 0, triggeredFrom = 'unknown' }) => {
    // Temporarily disable push notifications for all users
    return null;
    
    const platform = Capacitor.getPlatform();
    const isMobilePlatform = platform === 'ios' || platform === 'android';
    if (!isMobilePlatform) return null;

    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(true);
    const [showDontShowAgain, setShowDontShowAgain] = useState(false);
    const [hideNutrition, setHideNutrition] = useState(false);
    const [delayedVisible, setDelayedVisible] = useState(false);
    const listenersAddedRef = useRef(false);

    const user = UserStore.getUser();
    const isInternal = user?.is_internal ?? false;

    const handleTokenRegistration = async (token, source = 'Auto') => {
        if (!token?.value) return;

        console.log(`[${source}] New push token detected, syncing to backend...`);
        store.set(LOCALSTORAGE_KEY_DEVICE_TOKEN, token.value);
        await storeTokenInBackend(token.value, platform);

        Analytics.trackPushNotificationPermission({ Action: source });
    };

    useEffect(() => {
        if (!isInternal) return;

        setHideNutrition(user?.preferences?.hide_nutrition || false);

        const timer = setTimeout(() => {
            setDelayedVisible(true);
        }, delay);

        return () => clearTimeout(timer);
    }, [delay]);

    useEffect(() => {
        if (!isInternal) return;

        if (!Capacitor.isPluginAvailable('PushNotifications')) {
            return;
        }

        let registrationListener;
        let registrationErrorListener;

        const run = async () => {
            if (!listenersAddedRef.current) {
                registrationListener = await PushNotifications.addListener('registration', async (token) => {
                    await handleTokenRegistration(token, 'Auto Sync');
                });

                registrationErrorListener = await PushNotifications.addListener('registrationError', (error) => {
                    console.error('Push registration error:', error);
                    setVisible(false);
                    setLoading(false);
                });
                listenersAddedRef.current = true;
            }

            const storedData = store.get(LOCALSTORAGE_KEY) || { count: 0, dontShow: false, lastDismissed: null };
            const storedToken = store.get(LOCALSTORAGE_KEY_DEVICE_TOKEN);

            if (storedData.dontShow || storedToken) {
                const permissionStatus = await PushNotifications.checkPermissions();

                const hasPermission = permissionStatus.receive === 'granted';

                if (force) {
                    if (!hasPermission) {
                        // User can re enable permissions
                        setVisible(true);
                        return;
                    }

                    if (storedToken && hasPermission) {
                        // Resync the token
                        await PushNotifications.register();
                        setVisible(false);
                        return;
                    }
                }

                // No need to show the prompt we already have permission and token
                if (storedToken && hasPermission) {
                    setVisible(false);
                    return;
                }

                // Show prompt to re request permission
                setVisible(true);
                return;
            }

            if (force) {
                setVisible(true);
                return;
            }

            // Check if 2 full daily visits have passed since lastDismissed
            if (storedData.lastDismissed) {
                const lastDismissedDate = new Date(storedData.lastDismissed);
                const today = new Date();

                const timeDifference = today.setHours(0, 0, 0, 0) - lastDismissedDate.setHours(0, 0, 0, 0);
                const daysPassed = timeDifference / (1000 * 60 * 60 * 24);
                if (daysPassed < 2) {
                    setVisible(false);
                    return;
                }
            }

            // Show "Don't Show Again" after two dismissals
            if (storedData.count >= 2) {
                setShowDontShowAgain(true);
            }

            store.set(LOCALSTORAGE_KEY, { ...storedData, count: storedData.count + 1 });

            setVisible(true);
            Analytics.trackPushNotificationPromptShown({ triggeredFrom });
        };
        run();
        return () => {
            if (registrationListener) registrationListener.remove();
            if (registrationErrorListener) registrationErrorListener.remove();
            listenersAddedRef.current = false;
        };
    }, [platform, force]);

    const closePrompt = (action = false, dontShowAgain = false) => {
        const storedData = store.get(LOCALSTORAGE_KEY) || { count: 0, dontShow: false, lastDismissed: null };

        if (action === 'Not Now') {
            storedData.lastDismissed = new Date().toISOString();
        }

        if (dontShowAgain) {
            storedData.dontShow = true;
        }

        store.set(LOCALSTORAGE_KEY, storedData);

        setVisible(false);
        setLoading(false);
        onClose();

        if (action) {
            Analytics.trackPushNotificationPrePermission({ action });
        }
    };

    const requestPushNotifications = async () => {
        if (!Capacitor.isPluginAvailable('PushNotifications')) {
            console.warn('PushNotifications plugin is not available on this platform.');
            return;
        }
        setLoading(true);
        try {
            const permStatus = await PushNotifications.requestPermissions();

            const optInStatus = permStatus.receive === 'granted' ? 'Enabled' : 'Denied';
            Analytics.trackPushNotificationOptInStatus({ 'Opt-In Status': optInStatus });

            if (permStatus.receive !== 'granted') {
                console.log('Push notifications permission denied.');
                setLoading(false);
                setVisible(false);
                return;
            }

            await PushNotifications.register();
            closePrompt();
        } catch (error) {
            console.error('Error in push notification setup:', error);
            setLoading(false);
            setVisible(false);
            throw error;
        }
    };

    if (!visible || !delayedVisible) return null;

    return ReactDOM.createPortal(
        <div className="el-push-notification-prompt">
            <Prompt
                buttons={[
                    {
                        label: loading ? 'Enabling ' : 'Enable Notifications',
                        onClick: requestPushNotifications,
                        variant: 'default',
                        disabled: loading,
                        format: 'wide',
                        size: 'large',
                        isLoading: loading,
                    },
                    {
                        label: 'Not Now',
                        onClick: () => closePrompt('Not Now'),
                        variant: 'outline',
                        format: 'wide',
                        size: 'large',
                        disabled: loading,
                    },
                    showDontShowAgain &&
                        !force && {
                            label: 'Don’t Show Again',
                            onClick: () => closePrompt('Don’t Show Again', true),
                            variant: 'outline',
                            className: 'el-btn el-large-btn el-raspberry-outline-btn link',
                            format: 'wide',
                            size: 'large',
                            disabled: loading,
                        },
                ].filter(Boolean)}
                onClose={() => closePrompt('App Close')}
            >
                {loading ? (
                    <div className="loading">
                        <Icon name="spinner2" />
                    </div>
                ) : (
                    <>
                        <h2>Stay on Track with Meal Reminders</h2>
                        <p>Enable notifications to help you achieve your nutrition goals:</p>
                        <ul>
                            <li>
                                <span className="icon">
                                    <Icon name="messages"></Icon>
                                </span>
                                <span className="text">
                                    Daily menu reminders to get you excited about what’s cooking
                                </span>
                            </li>
                            <li>
                                <span className="icon">
                                    <Icon name="schedule3"></Icon>
                                </span>
                                <span className="text">Weekly meal planning and grocery reminders to save time</span>
                            </li>
                            {!hideNutrition && (
                                <li>
                                    <span className="icon">
                                        <Icon name="meal-plan"></Icon>
                                    </span>
                                    <span className="text">Meal logging reminders to track your progress</span>
                                </li>
                            )}
                        </ul>
                    </>
                )}
            </Prompt>
        </div>,
        document.getElementById('root')
    );
};

export default PushNotificationPrompt;
