'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import debounce from 'lodash.debounce';

import OrdersPopup from '../../Dashboard/Feed/OrdersPopup.react';
import Button from '../../Widgets/Button.react';
import PushNotificationPrompt from '@components/Widgets/PushNotificationPrompt.react';

import GroceryActions from '../../../actions/GroceryActions';
import MealActions from '../../../actions/MealActions';
import { getPrimaryMeal } from '../../../utils/Meals';
import { areMealsGroceriesPurchased, areMealsInGroceries, getMealsInGroceries } from '../../../utils/Grocery';
import Analytics from '../../../utils/Analytics';

import './GroceryButton.scss';
import GroceryStore from '../../../stores/GroceryStore';
import moment from 'moment';

export default class GroceryButton extends Component {
    static propTypes = {
        meals: PropTypes.array,
        date: PropTypes.object,
        mealType: PropTypes.string,

        defaultPopupPositionClass: PropTypes.string,
    };

    static defaultProps = {
        defaultPopupPositionClass: 'el-popup-bottom-center',
    };

    static contextTypes = {
        user: PropTypes.object,

        orders: PropTypes.array,
        recipes: PropTypes.object,
        foods: PropTypes.object,

        groceries: PropTypes.array,
        loaded: PropTypes.bool,

        syncMealsToGroceries: PropTypes.func,
        purchaseGroceryItems: PropTypes.func,
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            isLoading: false,
            showPushNotificationPrompt: false,
        };

        this.debounceResetAlert = debounce(this.resetAlert, 5000);
    }

    resetAlert = () => {
        this.setState({
            alert: null,
            isLoading: false,
        });
    };

    getPrimaryMeal = (meals) => {
        const { recipes, foods } = this.context;
        return getPrimaryMeal(meals, recipes, foods);
    };

    getPreviousGroceriesAndMeals = () => {
        const { groceries = [], purchased = [] } = this.context;
        const { meals = [] } = this.props;

        // Combine groceries and purchased into a new array and deep-clone it
        const previousGroceries = JSON.parse(JSON.stringify([...groceries, ...purchased]));
        const previousMeals = JSON.parse(JSON.stringify(getMealsInGroceries(meals, previousGroceries)));

        return { previousGroceries, previousMeals };
    };

    getAddRemoveAnalyticsTraits = (dirtyMeals, dirtyGroceries, previousGroceries, deleteGroceries = null) => {
        const { date, mealType } = this.props;
        const { recipes, foods } = this.context;
        const { food, titles } = getPrimaryMeal(dirtyMeals, recipes, foods);

        const traits = {
            'Meal Type': mealType,
            Date: moment(date).format('YYYY-MM-DD'),
            'Meal Titles': titles,
        };

        if (dirtyMeals?.length > 0) {
            traits['Meal UUIDs'] = dirtyMeals.map((m) => m.uuid);
            traits['Meal Types'] = dirtyMeals.map((m) => m.meal_type);
            traits['Meal Scalings'] = dirtyMeals.map((m) => m.scaling);
        }

        if (dirtyGroceries?.length > 0) {
            traits['Groceries'] = dirtyGroceries.map((g) => g.grocery);
            traits['Grocery Foods'] = dirtyGroceries.map((g) => g.food_uuid);
            traits['Grocery UUIDs'] = dirtyGroceries.map((g) => g.uuid);
            traits['Grocery Weights'] = dirtyGroceries.map((g) => g.weight);
            traits['Grocery Quantities'] = dirtyGroceries.map((g) => g.quantity);

            traits['Current Grocery Added Or Reduced Quantities'] = dirtyGroceries.map((g) => {
                const prevGrocery = previousGroceries.find((pg) => pg.uuid === g.uuid);
                const prevQuantity = prevGrocery?.quantity || 0;
                //If deleting groceries reverse subtraction operation
                return Array.isArray(deleteGroceries) ? prevQuantity - g.quantity : g.quantity - prevQuantity;
            });

            const anomalies = dirtyGroceries
                .filter((g) => g.food_uuid)
                .filter((g) => {
                    const food = foods[g.food_uuid];

                    if (!food || g.quantity <= 20) {
                        return false;
                    }

                    return food.category === 'Produce';
                });

            if (anomalies.length > 0) {
                traits['Grocery Anomaly'] = true;
            }
        }

        if (deleteGroceries?.length > 0) {
            traits['Deleted Groceries'] = deleteGroceries.map((g) => g.grocery);
            traits['Deleted Grocery Foods'] = deleteGroceries.map((g) => g.food_uuid);
            traits['Deleted Grocery UUIDs'] = deleteGroceries.map((g) => g.uuid);
            traits['Deleted Grocery Weights'] = deleteGroceries.map((g) => g.weight);
            traits['Deleted Grocery Quantities'] = deleteGroceries.map((g) => g.quantity);
        }

        if (food?.product_type) {
            traits['Product Type'] = food.product_type;
        }

        if (food?.brand_name) {
            traits['Brand Name'] = food.brand_name;
        }

        if (food?.brand_uuid) {
            traits['Brand UUID'] = food.brand_uuid;
        }

        return traits;
    };

    purchaseGroceryItems = (meals) => {
        const { purchaseGroceryItems } = this.context;
        const { previousGroceries } = this.getPreviousGroceriesAndMeals();

        this.setState({ isLoading: true });

        purchaseGroceryItems(meals)
            .then((dirtyGroceries) => {
                this.setState({ alert: 'Groceries On Hand' }, this.debounceResetAlert);

                Analytics.ingredientsOnHand(this.getAddRemoveAnalyticsTraits(meals, dirtyGroceries, previousGroceries));
            })
            .finally(() => {
                this.setState({ isLoading: false });
            });
    };

    removeMealsFromGroceries = (meals) => {
        this.setState({ isLoading: true });

        const { previousGroceries } = this.getPreviousGroceriesAndMeals();

        GroceryActions.asyncRemoveMealsFromGroceries(meals)
            .then(({ dirtyMeals, dirtyGroceries, deleteGroceries }) => {
                this.setState({ alert: 'Removed from List' }, this.debounceResetAlert);

                Analytics.removeMealFromGroceries(
                    this.getAddRemoveAnalyticsTraits(dirtyMeals, dirtyGroceries, previousGroceries, deleteGroceries)
                );
            })
            .finally(() => {
                this.setState({ isLoading: false });
            });
    };

    syncMealsToGroceries = async (meals) => {
        const { syncMealsToGroceries } = this.context;
        const { previousGroceries, previousMeals } = this.getPreviousGroceriesAndMeals();

        this.setState({ isLoading: true });

        const { dirtyMeals, dirtyGroceries } = await syncMealsToGroceries(meals, true);

        this.setState({ alert: 'Added to List', isLoading: false }, this.debounceResetAlert);
        MealActions.upsertMeals(dirtyMeals);

        Analytics.addMealToGroceryList(
            this.getAddRemoveAnalyticsTraits(dirtyMeals, dirtyGroceries, previousGroceries)
        );

        this.setState({ showPushNotificationPrompt: true });
    };

    getCTAButton = () => {
        const { showAlert, meals, orders: itemOrders, defaultPopupPositionClass } = this.props;
        const { isLoading, alert } = this.state;
        const { orders: allOrders } = this.context;

        let ctaBtn = null;
        let indicator = null;

        const groceries = GroceryStore.getGroceries();
        const validMeals = meals.filter((m) => !m.deleted && m.meal_type !== 'leftover');

        // @todo - area of optimization. The isMealInGroceries calls can be moved to Feed.getItems so that it's
        // essentially pre-cached when it should be, not calculated on rendering
        const areInGroceries = areMealsInGroceries(validMeals, groceries);
        const areGroceriesPurchased =
            areMealsGroceriesPurchased(validMeals, groceries, allOrders) ||
            (areInGroceries && !areMealsInGroceries(validMeals, groceries, true));
        const isMealOrdered = !!(itemOrders || []).filter((o) => o.status !== 'SUBMITTED').length;

        if (areGroceriesPurchased && isMealOrdered) {
            ctaBtn = (
                <OrdersPopup
                    orders={itemOrders}
                    positionClassName={defaultPopupPositionClass}
                    className="el-popup-dark groceries-are-purchased grocery-order-popup"
                    dropdownBtnClass="groceries-btn groceries-cta-btn groceries-purchased"
                    buttonTitle="Remove meal ingredients from grocery list"
                    button={isLoading ? <i className="feather feather-loader" /> : <i className="icon-check" />}
                />
            );

            indicator = (
                <OrdersPopup
                    className="el-popup-dark grocery-helper-text grocery-order-popup"
                    orders={itemOrders}
                    positionClassName="el-popup-bottom-center"
                    button={
                        <p className="groceries-purchased">
                            Purchased <i className="feather feather-info" />
                        </p>
                    }
                />
            );
        } else {
            let buttonProps = {};

            if (areGroceriesPurchased) {
                buttonProps.title = 'Remove meal ingredients from grocery list';
                buttonProps.buttonClassName = 'groceries-btn groceries-cta-btn groceries-purchased';
                buttonProps.iconClassName = 'icon-check';
                buttonProps.onClick = () => this.removeMealsFromGroceries(validMeals);
                indicator = (
                    <div className="grocery-helper-text">
                        <p className="groceries-purchased">Grocery on Hand</p>
                    </div>
                );
            } else if (areInGroceries) {
                buttonProps.title = 'Mark meal ingredients as purchased';
                buttonProps.buttonClassName = 'groceries-btn added-to-groceries';
                buttonProps.iconClassName = 'icon-check';
                buttonProps.onClick = () => this.purchaseGroceryItems(validMeals);
                indicator = (
                    <div className="grocery-helper-text">
                        <p className="added-to-groceries">Added to list</p>
                    </div>
                );
            } else {
                buttonProps.title = 'Add meal ingredients to grocery list';
                buttonProps.buttonClassName = 'groceries-btn';
                buttonProps.iconClassName = 'icon-add-to-cart';
                buttonProps.onClick = () => this.syncMealsToGroceries(validMeals);
                indicator = null;
            }

            ctaBtn = (
                <Button
                    title={buttonProps.title}
                    isLoading={isLoading}
                    onClick={isLoading ? null : buttonProps.onClick}
                    className={buttonProps.buttonClassName}
                >
                    {!isLoading && showAlert && alert ? <div className="alert">{alert}</div> : null}
                    {!isLoading ? <i className={buttonProps.iconClassName} /> : null}
                </Button>
            );
        }

        return { ctaBtn, indicator };
    };

    getLastRunDate = (runDay) => {
        if (!runDay) {
            return;
        }

        const today = moment();
        const targetDay = moment().day(runDay);

        while (targetDay.isAfter(today)) {
            targetDay.subtract(7, 'days');
        }

        const latestDate = targetDay.format('YYYY-MM-DD');

        return latestDate;
    };

    shouldRenderGroceryBtn = (primary) => {
        const { user } = this.context;

        const { run_day, days_ahead, for_days } = user?.features?.auto_populate_groceries || {};

        if (run_day && days_ahead && for_days) {
            const lastRunDate = this.getLastRunDate(run_day);
            const startDate = moment(lastRunDate).add(days_ahead, 'days');
            const endDate = moment(startDate).add(for_days - 1, 'days');

            const showGroceryBtn = moment(primary.date).isBetween(startDate, endDate, undefined, '[]');

            if (!showGroceryBtn) {
                return null;
            }
        }

        return true;
    };

    render = () => {
        const { meals, primary, showIndicator, className } = this.props;
        const { showPushNotificationPrompt } = this.state;

        if (!primary) {
            return null;
        }

        const showGroceryBtn = this.shouldRenderGroceryBtn(primary); // for auto-populate-groceries

        if (!showGroceryBtn) {
            return null;
        }

        // Are all these meals excluded from the grocery list?
        const excludedMeals = meals.filter((meal) => {
            if (meal.deleted) {
                return true;
            }

            const { content } = this.getPrimaryMeal([meal]);

            return content && content.exclude_from_groceries;
        });

        if (excludedMeals.length === meals.length) {
            return null;
        }

        if (!['fresh', 'food'].includes(primary.meal_type)) {
            return null;
        }

        const { ctaBtn, indicator } = this.getCTAButton(meals);

        if (!ctaBtn) {
            return null;
        }

        return (
            <>
                <div className={classNames('groceries-control', className)}>
                    {ctaBtn}
                    {showIndicator && indicator ? indicator : null}
                </div>
                {showPushNotificationPrompt && (
                    <PushNotificationPrompt
                        delay={3000}
                        onClose={() => {
                            this.setState({ showPushNotificationPrompt: false });
                        }}
                        triggeredFrom={'GroceryButton'}
                    />
                )}
            </>
        );
    };
}
